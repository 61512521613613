import {safeLazyImport} from 'bigdatr-style';
import {LazyRoute, Parse} from 'bigdatr-style';
import {OrderBy, Query} from '~/filters/data/parseFilters';
import {EntityListArgs} from '~/pages/EntityListRoute';
import {EntityType} from './ApiForEntities';

const EntityAttributes = safeLazyImport(() => import('~/pages/EntityAttributes'));
const EntityRules = safeLazyImport(() => import('~/pages/EntityRules'));
const EntityWordCloud = safeLazyImport(() => import('~/pages/EntityWordCloud'));
const EntityHistoryRoute = safeLazyImport(() => import('~/pages/EntityHistoryRoute'));
const EntityList = safeLazyImport(() => import('~/pages/EntityListRoute'));
const EntityCreate = safeLazyImport(() => import('~/pages/EntityCreate'));

export default {
    entityList: LazyRoute<EntityListArgs>({
        path: '/entity-editor/list/:type',
        component: EntityList,
        parse: {
            type: Parse.param.string((x) => (x as EntityType) ?? 'industry'),
            archived: Parse.query.boolean((x) => Boolean(x)),
            incompleteRelationships: Parse.query.boolean((x) => x ?? false),
            incompleteRule: Parse.query.boolean((x) => x ?? false),
            activeRules: Parse.query.boolean((x) => x ?? false),
            orderBy: Parse.query.JSON((x) => x as OrderBy),
            search: Parse.query.string((x) => x ?? ''),
            page: Parse.query.number((x) => x ?? 1),
            filters: Parse.query.JSON((x) => (x ?? {}) as EntityListArgs['filters'])
        }
    }),
    entityCreate: LazyRoute<{type: EntityType}>({
        path: '/entity/:type/create',
        component: EntityCreate,
        parse: {
            type: Parse.param.string((x) => x as EntityType)
        }
    }),
    entityAttributes: LazyRoute<{id: string; type: EntityType; backLinkFilters?: EntityListArgs}>({
        path: '/entity/:type/:id/attributes',
        component: EntityAttributes,
        parse: {
            type: Parse.param.string((x) => x as EntityType),
            id: Parse.param.string((x) => x as string),
            backLinkFilters: Parse.state((x) => (x ?? {}) as EntityListArgs)
        }
    }),
    entityRules: LazyRoute<{id: string; type: EntityType; backLinkFilters?: EntityListArgs}>({
        path: '/entity/:type/:id/rule(s)?',
        component: EntityRules,
        parse: {
            type: Parse.param.string((x) => x as EntityType),
            id: Parse.param.string((x) => x as string),
            backLinkFilters: Parse.state((x) => (x ?? {}) as EntityListArgs)
        }
    }),
    entityWordCloud: LazyRoute<{
        id: string;
        type: EntityType;
        filters?: Query;
        view?: 'list' | 'wordCloud';
        backLinkFilters?: EntityListArgs;
    }>({
        path: '/entity/:type/:id/word-cloud',
        component: EntityWordCloud,
        parse: {
            type: Parse.param.string((x) => x as EntityType),
            id: Parse.param.string((x) => x as string),
            filters: Parse.query.JSON((x) => x as Query),
            view: Parse.query.string((x) => (x ?? 'list') as 'list' | 'wordCloud'),
            backLinkFilters: Parse.state((x) => (x ?? {}) as EntityListArgs)
        }
    }),
    entityHistory: LazyRoute<{id: string; type: EntityType}>({
        path: '/entity/:type/:id/history',
        component: EntityHistoryRoute,
        parse: {
            type: Parse.param.string((x) => x as EntityType),
            id: Parse.param.string((x) => x as string)
        }
    })
};
